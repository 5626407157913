import React from "react";
import SharedHeader from "../../shared/header";
import header from "../../../assets/img/components/header/jason-goodman-Oalh2MojUuk-unsplash.jpg";

export default function Header() {
  return (
    <section id="header">
      <SharedHeader
        image={header}
        imageAltText="Bild von einer Person, die ihre Inhalte vor der Gruppe vorstellt."
        classNameImage="header-image"
        align="left"
        titlesize="display-2"
        titlecolor="blue"
        title={<>Sie haben 
          eine Herausforderung</>}
        text={
          <>
            in Ihrem Alltag, für die Sie dringend eine Lösung suchen?
          </>
        }
        textsize="body-3"
        buttontext="Herausfordung einreichen"
        buttonGridTemplateAreas={`'. icon text' 'vblue . .'`}
        buttonTextBackgroundColor="yellow"
        buttonTextColor="black"
        buttonIconColor="black"
        link="/authority/#breakAuthorities"
      />
    </section>
  );
}
